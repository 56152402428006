<template>
  <el-row >
    <el-col class="top" :sapn="24">
      <el-carousel
        :interval="5000"
        arrow="always"
        :width="width"
        indicator-position="inside"
        ref="carousel"
        @click.native="linkTo"
      >
        <el-carousel-item v-for="(item, index) in (centerNavList.bannerList || '').slice(0,6)" :key="index" >
          <img :src="item.img" :alt="item.title"/>
        </el-carousel-item>
      </el-carousel>
    </el-col>
    <el-col :sapn="24" class="bottom"  >
      <div v-for="(item, index) in centerNavList.activityDetail" :key="index" >
        <img v-if='item'  :src="item.pic" alt="图片"  @click="jumpUrl(item.url,item.name)"/>
      </div>
    </el-col>
  </el-row>
</template>


<style lang="less" scoped>
@import "~style/index.less";
/* @media screen and (max-width: 1280px) {
  .bottom > div {
    width: 175px !important;
  }
} */

.top {
  width: 100%;
  height:274px;
  overflow: hidden;
}
.el-carousel__item{
  cursor: pointer;
    img {
      width: 100%;
      height:274px;
    }
    h3 {
      color: #475669;
      font-size: 18px;
      opacity: 0.75;
      line-height:274px;
      margin: 0;
    }
}
.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}
.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

.bottom {
  height: 146px;
  margin-top:10px;
  box-sizing: border-box;
  div {
    height: 100%;
    margin-right:11px;
    width: 237px;
    float: left;
    cursor: pointer;
    /* border-radius: 13px; */
    overflow: hidden;
    &:hover{
        box-shadow: 0px 5px 15px -8px #949090;
        /* border-radius:15px; */
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
}

.bottom > div:last-child {
  margin-right: 0px;
}
// .zone {
//   background: black;
//   width: 755px;
//   el-main {
//     width: 100%;
//     height: 186px;
//     background: blue;
//   }
// }
</style>

<script>
export default {
  name: "CenterNav",
    inject:['reload'],
  data() {
    return {
      width: "733px",
      picsArr:[],
      banner:[]
    };
  },
  props: {
    centerNavList: {
      type: Object,
    },
  },
  
  methods: {
    
    linkTo(){
// item.advTarget  外联1  详情页  2  活动页  3-->
      // v-if="item.advTarget==2" @click="jumpDetail(item.goodsId,item.isRecentExpiration)"
      let activeIndex = this.$refs.carousel.activeIndex
      if(this.centerNavList.bannerList[activeIndex].advTarget==2){
          // 跳商品详情
        this.jumpDetail(this.centerNavList.bannerList[activeIndex].goodsId,this.centerNavList.bannerList[activeIndex].isRecentExpiration)
      }else if(this.centerNavList.bannerList[activeIndex].advTarget==1){
         if(this.centerNavList.bannerList[activeIndex].url==''){
            return false
         }else{
          // 跳外链接
          // alert(this.centerNavList.bannerList[activeIndex].url)
          // window.location.href='https://'+window.location.host+'/#/topicPage?Id=1733026858474246146'
          // window.location.href=this.centerNavList.bannerList[activeIndex].url
                let str=this.centerNavList.bannerList[activeIndex].url
                console.log(this.centerNavList.bannerList[activeIndex].url)
                if(str.includes('amp;')){
                  str=str.replace(/amp;/,"")
                }
                if(str.includes('topicPage')){
                  let tagId=str.split('Id=')[1]
                  this.$router.push({name:"TopicPage",query:{Id:tagId}})
                }else{
                  window.open(str,'_self');
                }
         }
      }else{
        if(this.centerNavList.bannerList[activeIndex].url==''){
            return false
        }else{
             // 跳活动链接
            window.location.href=this.centerNavList.bannerList[activeIndex].url
        }
        // this.jumpUrl(this.bannerList[activeIndex].url,this.bannerList[activeIndex].title)
      }
      // this.$router.push(this.bannerList[activeIndex].url)
    },
    jumpUrl(urlType,name) {
      const query = urlType.split("?")[1].split("=")[1]
      let tagId = query;
       this.$router.push({name:"MarketingList",query:{tagId:tagId,name:name}})
    },
     jumpDetail(id,isRecentExpiration) {
      this.$router.push({ name: "ProductDetail", query: { id: id,isRecentExpiration:isRecentExpiration } });
    },
  },
 
  created() {
  
  },
  components: {},
};
</script>
